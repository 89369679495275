import ReactGa from "react-ga";
import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";

function GoogleAnalytics() {
    let location = useLocation()

    function getTitle(path) {
        switch (path) {
            case "/#features" :
                return "Team Whiteboard: Features"
            case "/#our-story" :
                return "Team Whiteboard: Our Story"
            case "/b" :
                return "Team Whiteboard: App"
            default:
                return "Team Whiteboard"
        }
    }

    useEffect(() => {
        const options = (process.env.NODE_ENV === "production")
            ? {}
            : {testMode: false, debug: true};

        let path = `${location.pathname}${location.hash}`;
        document.title = getTitle(path);

        ReactGa.set({
            path,
            ...options
        });
        ReactGa.pageview(path)
    }, [location]);

    return (<></>);
}

export default GoogleAnalytics;