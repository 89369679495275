import {createAsyncThunk} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../../Store";

export const logout = createAsyncThunk<object, void, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "users/logout",
    async () => {
        return {empty: ""}
    }
)
