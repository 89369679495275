import {createAsyncThunk, Dispatch} from "@reduxjs/toolkit";
import axios from "axios";
import TemplateInterface from "../slice/TemplateInterface";
import {AppDispatch, RootState} from "../../Store";
import {Credentials, GoogleCredentialsInterface} from "../slice/GoogleCredentialsSlice";

interface LoginParam {
    tokenObj: Credentials
}

export const login = createAsyncThunk<any, LoginParam, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "users/login",
    async ({tokenObj}, thunkAPI) => {
        try {
            let userResponse = await axios.get("https://www.googleapis.com/oauth2/v1/userinfo?alt=json", {
                headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`}
            });
            return {user: userResponse.data, tokenObj};
        } catch (e: any) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    }
)
