import {createAsyncThunk} from "@reduxjs/toolkit";
import {getFileContent} from "../googleApi";
import TemplateInterface from "../slice/TemplateInterface";
import {AppDispatch, RootState} from "../../Store";

export const loadTemplate = createAsyncThunk<TemplateInterface | undefined, void, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "template/load",
    async (_, thunkAPI) => {
        try {
            const {files, googleCredentials} = thunkAPI.getState();
            if(!files?.templateFile || !googleCredentials?.tokenObj) return;

            let fileContentResponse = await getFileContent<TemplateInterface>(files.templateFile, googleCredentials.tokenObj);
            return fileContentResponse.data;
        } catch (e: any) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    });
