import {createSlice} from "@reduxjs/toolkit";
import {loadRootFolder} from "../thunk/LoadRootFolder";
import {logout} from "../thunk/LogoutThunk";
import {FilesInterface} from "../../AppStateInterface";
import {RootState} from "../../Store";

const initFiles: FilesInterface = {};

export const FilesSlice = createSlice({
    name: 'files',
    initialState: initFiles,
    reducers: {
        setRootFolder: (state, action) => {
            state.rootFolder = action.payload;
        },
        setInvoiceFile: (state, action) => {
            state.invoiceFile = action.payload;
        },
        setTemplateFile: (state, action) => {
            state.templateFile = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(loadRootFolder.fulfilled, (state, action) => {
            state.rootFolder = action?.payload;
        }).addCase(logout.fulfilled, (state, action) => {
            state.rootFolder = undefined;
            state.invoiceFile = undefined;
            state.templateFile = undefined;
        });
    }
});

export const getFiles = (state: RootState) => state?.files

export const {setRootFolder, setInvoiceFile, setTemplateFile} = FilesSlice.actions;

export default FilesSlice.reducer;