import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {logout} from "../thunk/LogoutThunk";
import TemplateInterface from "./TemplateInterface";
import {RootState} from "../../Store";
import {loadTemplate} from "../thunk/LoadTemplateThunk";

export const initTemplate: TemplateInterface = {
    companyName: "Example Company",
    addressText: `<b>ABN: 123456789</b>
1 George St
Sydney 2000
M: 0400 000 000
E: alex.constanti.ac@gmail.com
W: <a href="www.teamwhiteboards.com">www.teamwhiteboards.com</a>`,
    paymentOptions: [`<b>EFT Details</b>
Ref: JaneDoe Invoice 38
BSB: 123-456
AccountNum: 1234567`,
        `<b>Bpay</b>
BpayCode: 123-456
BillerCode: 1234567
Ref: 1234567`,
        ` `, ` `],
}

function setStateValues(state: TemplateInterface, newState: TemplateInterface) {
    for (let stateKey in state) {
        state[stateKey] = newState[stateKey];
    }
}

export const TemplateSlice = createSlice({
    name: 'template',
    initialState: initTemplate,
    reducers: {
        setTemplate: (state, action: PayloadAction<TemplateInterface>) => {
            setStateValues(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(loadTemplate.fulfilled, (state, action) => {
            if (!action.payload) return;
            setStateValues(state, action.payload);
        }).addCase(logout.fulfilled.type, (state) => {
            setStateValues(state, initTemplate);
        })
    }
});

export const getTemplate = (state: RootState) => {
    return state.template;
}

export const {setTemplate} = TemplateSlice.actions;

export default TemplateSlice.reducer;
