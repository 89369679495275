import {createAsyncThunk} from "@reduxjs/toolkit";
import {createFile, findFiles} from "../googleApi";
import {setTemplateFile} from "../slice/FilesSlice";
import {initTemplate} from "../slice/TemplateSlice";
import {loadTemplate} from "./LoadTemplateThunk";
import {AppDispatch, RootState} from "../../Store";
import TemplateInterface from "../slice/TemplateInterface";

export const loadTemplateFileThunk = createAsyncThunk<TemplateInterface | undefined, void, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "files/templateFile",
    async (_, thunkAPI) => {
        try {
            const {files, googleCredentials, config} = thunkAPI.getState();
            if (!files.rootFolder || !googleCredentials?.tokenObj) return;

            let fileResponse = await findFiles(config.templateFileName, googleCredentials.tokenObj);

            let templateFile;
            if ((fileResponse?.files || []).length === 0) {
                templateFile = await createFile(
                    config.templateFileName,
                    [`${files.rootFolder.id}`],
                    JSON.stringify(initTemplate),
                    googleCredentials.tokenObj);
                templateFile = templateFile.data;
            } else {
                templateFile = fileResponse.files[0];
            }

            thunkAPI.dispatch(setTemplateFile(templateFile));
            thunkAPI.dispatch(loadTemplate())
            return templateFile;
        } catch (e: any) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    });
