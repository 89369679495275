import {createAsyncThunk} from "@reduxjs/toolkit";
import {getFileContent} from "../googleApi";
import InvoiceInterface from "../slice/InvoiceInterface";
import {AppDispatch, RootState} from "../../Store";

export const loadInvoices = createAsyncThunk<InvoiceInterface[] | undefined, void, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "invoice/load",
    async (_, thunkAPI) => {
        try {
            const {files, googleCredentials} = thunkAPI.getState();
            if (!files?.invoiceFile || !googleCredentials?.tokenObj) return;

            let fileContentResponse = await getFileContent<string>(files.invoiceFile, googleCredentials.tokenObj);
            return csvToArray(fileContentResponse.data);
        } catch (e: any) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    });

function splitCells(row: string, delimiter = ",") {
    let values: Array<string> = [];
    let partialValue = "";
    row.split(delimiter).forEach(it => {
        if (partialValue === "") {
            if (it.startsWith("\"") && it.endsWith("\"")) {
                values.push(it);
            } else if (it.startsWith("\"")) {
                partialValue += `${it},`;
            } else {
                values.push(it.replace(/"/g, ""));
            }
        } else {
            if (it.endsWith("\"")) {
                partialValue += it;
                values.push(partialValue);
                partialValue = "";
            } else {
                partialValue += it;
            }
        }
    });
    return values;
}

function parseCells(cells: Array<string>) {
    return cells.map(it => {
        if (it.indexOf(",") > -1) {
            return splitCells(it.replace(/"/g, ""));
        } else if (/^".*"$/.test(it)) {
            return it.replace(/"/g, "");
        } else if (/^[0-9]+\.[0-9]{1,2}$/.test(it)) {
            return parseFloat(it);
        } else if (/^[0-9]+$/.test(it)) {
            return parseInt(it);
        } else if (`${true}` === it) {
            return true
        } else if (`${false}` === it) {
            return false
        } else {
            return it;
        }
    });
}

export function csvToArray(csvString: string, delimiter = ","): InvoiceInterface[] {
    const rows = csvString.slice(csvString.indexOf("\n") + 1).split("\n");

    if (rows.length === 1 && rows[0] === "") return [];

    return rows.map(row => {
        let temp = splitCells(row, delimiter);
        let cells = parseCells(temp);

        let obj: InvoiceInterface = {
            id: cells[0] as number,
            email: cells[1] as string,
            toName: cells[2] as string,
            createdDate: cells[3] as string,
            dueDate: cells[4] as string,
            description: cells[5] as string,
            registeredForGST: cells[6] as boolean,
            includesGST: cells[7] as boolean,
            totalAmount: cells[8] as number,
            status: cells[9] as string[],
            items: [],
        }

        return obj;
    });
}
