import {createSlice} from "@reduxjs/toolkit";
import {RootState} from "../../Store";

export interface ReportsInterface {
    totalInvoiceAmount: number
}

const init: ReportsInterface = {
    totalInvoiceAmount: 0
}

export const ReportsSlice = createSlice({
    name: 'reports',
    initialState: init,
    reducers: {},
    extraReducers: builder => {
        // builder.addCase(saveInvoice.fulfilled, (state, action) => {
        //     if (!action.payload) return;
        //     setStateValues(state, action.payload);
        // }).addCase(logout.fulfilled.type, (state) => {
        //     setStateValues(state, initTemplate);
        // });
    }
})

export const getReports = (state: RootState) => state.reports;

export default ReportsSlice.reducer;