import {configureStore} from '@reduxjs/toolkit';
import {useDispatch} from "react-redux";
import GoogleCredentialsSlice from "./invoice/slice/GoogleCredentialsSlice";
import LoggedInUserSlice from "./invoice/slice/LoggedInUserSlice";
import InvoiceSlice from "./invoice/slice/InvoiceSlice";
import ConfigSlice from "./invoice/slice/ConfigSlice";
import FilesSlice from "./invoice/slice/FilesSlice";
import TemplateSlice from "./invoice/slice/TemplateSlice";
import ReportsSlice from "./invoice/slice/ReportsSlice";

const store = configureStore({
    reducer: {
        config: ConfigSlice,
        googleCredentials: GoogleCredentialsSlice,
        user: LoggedInUserSlice,
        invoice: InvoiceSlice,
        files: FilesSlice,
        template: TemplateSlice,
        reports: ReportsSlice,
    },
});

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export type RootState = ReturnType<typeof store.getState>

export default store;
