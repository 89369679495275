import {createSlice} from "@reduxjs/toolkit";

export const ConfigSlice = createSlice({
    name: 'config',
    initialState: {
        rootFolder: "InvoiceMate",
        invoiceFileName: "invoices.csv",
        templateFileName: "template.json",
    },
    reducers: {},
});

export default ConfigSlice.reducer;
