import {createSlice} from '@reduxjs/toolkit'
import {login} from "../thunk/LoginThunk";
import {logout} from "../thunk/LogoutThunk";
import {RootState} from "../../Store";

export interface Credentials {
    token_type: string
    access_token: string
}

export interface GoogleCredentialsInterface {
    tokenObj?: Credentials
}

const init: GoogleCredentialsInterface = {}

export const GoogleCredentialsSlice = createSlice({
    name: 'auth/googleCredentials',
    initialState: init,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.tokenObj = action?.payload?.tokenObj;
        }).addCase(logout.fulfilled, (state) => {
            state.tokenObj = undefined;
        });
    }
});

export const getCredentials = (state: RootState) => {
    return state?.googleCredentials;
}

export default GoogleCredentialsSlice.reducer;
