import {createAsyncThunk} from "@reduxjs/toolkit";
import {createFile, findFiles} from "../googleApi";
import {setInvoiceFile} from "../slice/FilesSlice";
import {loadInvoices} from "./LoadInvoicesThunk";
import {GoogleFile} from "../../AppStateInterface";
import {AppDispatch, RootState} from "../../Store";

export const loadInvoiceFileThunk = createAsyncThunk<GoogleFile | undefined, void, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "files/invoiceFile",
    async (_, thunkAPI) => {
        try {
            const {files, googleCredentials, config} = thunkAPI.getState();
            if(!googleCredentials?.tokenObj) return;

            let fileResponse = await findFiles(config.invoiceFileName, googleCredentials.tokenObj);

            let invoiceFile;
            if ((fileResponse?.files || []).length === 0) {
                invoiceFile = await createFile(
                    config.invoiceFileName,
                    [`${files?.rootFolder?.id}`],
                    "id,email,toName,dueDate,description,registeredForGST,includesGST,totalAmount,status\n",
                    googleCredentials.tokenObj);
                invoiceFile = invoiceFile.data;
            } else {
                invoiceFile = fileResponse.files[0];
            }

            await thunkAPI.dispatch(setInvoiceFile(invoiceFile));
            await thunkAPI.dispatch(loadInvoices())
            return invoiceFile;
        } catch (e: any) {
            console.log("Error", e.response.data)
            thunkAPI.rejectWithValue(e.response.data)
        }
    });
