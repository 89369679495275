import {createSlice} from '@reduxjs/toolkit';
import {loadInvoices} from "../thunk/LoadInvoicesThunk";
import {logout} from "../thunk/LogoutThunk";
import InvoiceInterface from "./InvoiceInterface";
import {RootState} from "../../Store";

const init: InvoiceInterface[] = []

export const InvoiceSlice = createSlice({
    name: 'invoice',
    initialState: init,
    reducers: {
        setInvoices: (state, action) => {
            state.push(...action.payload ?? []);
        },
        addInvoice: (state, action) => {
            state.push(action.payload);
        },
        deleteInvoice: (state, action) => {
            return state.filter(it => it.id !== action.payload);
        }
    },
    extraReducers: builder => {
        builder.addCase(loadInvoices.fulfilled, (state, action) => {
            state.push(...action.payload ?? []);
        }).addCase(logout.fulfilled, (state, action) => {
            return [];
        });
    }
});

export const getInvoices = (state: RootState) => {
    return state?.invoice;
}

export const {setInvoices, addInvoice, deleteInvoice} = InvoiceSlice.actions;

export default InvoiceSlice.reducer;
