import {createAsyncThunk} from "@reduxjs/toolkit";
import {createFolder, findFiles} from "../googleApi";
import {setRootFolder} from "../slice/FilesSlice";
import {loadInvoiceFileThunk} from "./LoadInvoiceFileThunk";
import {loadTemplateFileThunk} from "./LoadTemplateFileThunk";
import {GoogleFile} from "../../AppStateInterface";
import {AppDispatch, RootState} from "../../Store";

export const loadRootFolder = createAsyncThunk<GoogleFile | undefined, void, {
    dispatch: AppDispatch
    state: RootState
    rejectValue: any
}>(
    "invoice/createRootFolder",
    async (_, {getState, dispatch, rejectWithValue}) => {
        try {
            const {config, googleCredentials} = getState();
            if(!googleCredentials?.tokenObj) return;

            let rootFolderResponse = await findFiles(config.rootFolder, googleCredentials.tokenObj);
            let rootFolder;
            if ((rootFolderResponse?.files || []).length === 0) {
                rootFolder = await createFolder(config.rootFolder, [], googleCredentials.tokenObj);
                rootFolder = rootFolder.data;
            } else {
                rootFolder = rootFolderResponse?.files[0];
            }

            await dispatch(setRootFolder(rootFolder));
            await dispatch(loadInvoiceFileThunk());
            await dispatch(loadTemplateFileThunk());

            return rootFolder;
        } catch (e: any) {
            console.log("Error", e.response.data)
            rejectWithValue(e.response.data)
        }
    });
