import axios from "axios";
import {GoogleFile} from "../AppStateInterface";
import {Credentials} from "./slice/GoogleCredentialsSlice";

export interface GoogleListResponse {
    "nextPageToken": string,
    "incompleteSearch": boolean,
    "files": Array<GoogleFile>
}

export async function findFiles(fileName: string, tokenObj: Credentials) {
    return (await axios.get<GoogleListResponse>(`https://www.googleapis.com/drive/v3/files?q=${encodeURIComponent(`name = "${fileName}" and trashed = false`)}`, {
        headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`}
    }))?.data || {};
}

export async function saveFile(file: GoogleFile, content: string, tokenObj: Credentials) {
    return await axios.patch<string, GoogleFile>(`https://www.googleapis.com/upload/drive/v3/files/${file.id}`,
        content,
        {headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`},});
}

export async function createFile(fileName: string, parents: Array<string>, content: string, tokenObj: Credentials): Promise<GoogleFile> {
    let invoiceFile = await axios.post<any, GoogleFile>("https://www.googleapis.com/drive/v3/files",
        {name: fileName, parents: parents},
        {headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`},});
    await saveFile(invoiceFile.data, content, tokenObj)

    return invoiceFile
}

export async function createFolder(fileName: string, parents: Array<string>, tokenObj: Credentials) {
    return await axios.post<any, GoogleFile>("https://www.googleapis.com/drive/v3/files",
        {"name": fileName, "mimeType": "application/vnd.google-apps.folder", parents: parents},
        {headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`},});
}

export async function getFileContent<T>(file: GoogleFile, tokenObj: Credentials) {
    return await axios.get<T>(`https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
        {headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`},});
}

export async function deleteFile(file: GoogleFile, tokenObj: Credentials) {
    await axios.delete(`https://www.googleapis.com/drive/v3/files/${file.id}`,
        {headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`},});
}

interface Message {
    id?: string,
    threadId?: string,
    labelIds?: string[],
    snippet?: string,
    historyId?: string,
    internalDate?: string,
    payload?: MessagePart,
    sizeEstimate?: number,
    raw?: string,
}

interface MessagePart {
    partId?: string,
    mimeType: string,
    filename?: string,
    headers: Header[],
    body: Body,
}

interface Header {
    name: string,
    value: string
}

interface Body {
    size: number
    data: string
}

export async function sendEmail(from: string, to: string, bcc: string, subject: string, body: string, tokenObj: Credentials) {
    let encodedBody = btoa(unescape(encodeURIComponent(
        `From: ${from}
To: ${to}
Bcc: ${from}
Subject: ${subject}
MIME-Version: 1.0
Content-Type: text/html; charset=utf-8

${body}
`)));
    let data: Message = {
        raw: encodedBody
            .replace(/\+/g, '-')
            .replace(/\//g, '_')
            .replace(/=+$/, ''),

    }

    return await axios.post(
        `https://gmail.googleapis.com/gmail/v1/users/${from}/messages/send`,
        data,
        {headers: {Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`},}
    );
}
