import {createSlice} from '@reduxjs/toolkit';
import {login} from "../thunk/LoginThunk";
import {logout} from "../thunk/LogoutThunk";
import {RootState} from "../../Store";

export interface User {
    email: string
}

export interface LoggedInUserInterface {
    user?: User
}

const init: LoggedInUserInterface = {
    user: undefined
};

export const LoggedInUserSlice = createSlice({
    name: 'user',
    initialState: init,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(login.fulfilled, (state, action) => {
            state.user = action?.payload?.user;
        }).addCase(logout.fulfilled, (state) => {
            state.user = undefined;
        });
    }
});

export const isLoggedIn = (state: RootState) => {
    return state?.user?.user !== undefined;
}

export const getUser = (state: RootState) => {
    return state?.user?.user;
}

export default LoggedInUserSlice.reducer;
