import "./index.css";
import "@fontsource/architects-daughter";

import 'bootstrap/dist/css/bootstrap.min.css';
import React, {lazy, Suspense} from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import GoogleAnalytics from "./GoogleAnalytics";
import ReactGa from "react-ga";
import {Provider} from 'react-redux';
import store from './Store';

const InvoiceSent = lazy(() => import("./invoice/page/Sent"));
const InvoiceTemplate = lazy(() => import("./invoice/page/Template"));
const InvoiceNew = lazy(() => import("./invoice/page/New"));
const InvoiceApp = lazy(() => import("./invoice/page/Home"));

const App = lazy(() => import('./App'));
const HomePage = lazy(() => import('./HomePage'));
const PageNotFound = lazy(() => import('./PageNotFound'));

const options = (process.env.NODE_ENV === "production")
    ? {}
    : {testMode: true, debug: true};
ReactGa.initialize('UA-199300729-1', options);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Suspense fallback={<div>Team Whiteboards is Loading...</div>}>
                    <Switch>
                        <Route exact path="/">
                            <HomePage/>
                        </Route>
                        <Route path="/b">
                            <App/>
                        </Route>
                        <Route path="/invoice/invoices">
                            <InvoiceSent/>
                        </Route>
                        <Route path="/invoice/new">
                            <InvoiceNew/>
                        </Route>
                        <Route path="/invoice/invoiceTemplate">
                            <InvoiceTemplate/>
                        </Route>
                        <Route path="/invoice">
                            <InvoiceApp/>
                        </Route>
                        <Route>
                            <PageNotFound/>
                        </Route>
                    </Switch>
                    <GoogleAnalytics options={options}/>
                </Suspense>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
)
;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
